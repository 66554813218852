import React from "react";
import { Breadcrumb } from "./layout";

const Page = ({ title, children }) => {
    return (
        <>
            {title && <Breadcrumb title={title} />}
            {children}
        </>
    );
}

export default Page;
