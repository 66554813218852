
import arFlag from "client/assets/images/languages/ar.png";
import enFlag from "client/assets/images/languages/en_US.png";
import trFlag from "client/assets/images/languages/tr_TR.png";

export const required = `Required`;
export const PageMode = {
  Add: "Add",
  Update: "Update",
};
export const TableInputType = {
  Image: "image",
  Toggle: "toggle",
};
export const languages = [
  { key: "ar", name_en: "Arabic", name: "عربي", flag: arFlag },
  { key: "en", name_en: "English", name: "English", flag: enFlag },
  // { key: "tr", name_en: "Turkish", name: "Türkçe", flag: trFlag },
];

export const allowedCountries = ["IQ", "JO", "RU", "SA"];
