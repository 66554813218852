import { Field } from "react-final-form";

const Error = ({ name, showerrorbeside }) => (
    <Field
        name={name}
        subscription={{ touched: true, error: true }}
        render={({ meta: { touched, error } }) =>
            touched && error ? (
                showerrorbeside ? (
                    <span className="sameline-error">{error}</span>
                ) : (
                    <div className="invalid-feedback d-block">{error}</div>
                )
            ) : null
        }
    />
);

export default Error;