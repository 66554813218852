import React from "react";
import { useTranslation } from "react-i18next";

const NoDataFound = (props) => {
  const { t } = useTranslation();

  return (
    <tr style={{ textAlign: "center", fontSize: "20px" }}>
      <td colSpan={props.colSpan}>
        {props.text ? props.text : t("NoDataFound")}
      </td>
    </tr>
  );
}

export default NoDataFound;
