import { Navigate, Route } from "react-router-dom";

import { Error500 } from "common/Pages";

const CommonRoutes = (currentLang) => [
  <Route path="500" element={<Error500 />} />,
  // <Route path="*" element={<Navigate to={`/${currentLang}`} replace />} />, // temporarly until we go live
  // <Route path="*" element={<Error404 />} />,
];

export default CommonRoutes;
