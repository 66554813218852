/* eslint-disable   */

import axios from "axios";
import authHeader from "../client/services/auth-header";

export const APIFilesRequest = async ({
  requestBody = {},
  url,
  queryParams = {},
  method,
}) => {
  var config = {
    method: `${method}`,
    url: `${url}`,
    headers: authHeader(),
    data: requestBody,
    responseType: "blob",
  };

  const response = {
    success: false,
    body: {},
  };

  // console.log(`APIFilesRequest :: requestBody: ${JSON.stringify(requestBody)}`);
  // console.log(`APIFilesRequest :: url: ${url}`);
  // console.log(`APIFilesRequest :: queryParams: ${JSON.stringify(queryParams)}`);

  return axios(config)
    .then((res) => {
      // console.log(`APIFilesRequest :: res: ${res}`);
      response.success = true;
      response.body = {
        fileUrl: window.URL.createObjectURL(new Blob([res.data])),
        fileName: res.headers["content-disposition"]?.split("=")[1],
      };
    })
    .catch(function (error) {
      // console.log(`error.response: ${JSON.stringify(error)}`);
      // console.log(
      //   `error?.response?.data?.errors.defaultMessage: ${error?.response?.data?.errors?.defaultMessage}`
      // );
      const resMessage = !!error?.response?.data?.errors
        ? error?.response?.data?.errors.map((e) => e?.defaultMessage)
        : [error?.response?.data?.message || error.message || error.toString()];
      // console.log(`APIFilesRequest :: catch :: resMessage:\n ${resMessage}`);
      response.message = resMessage;
      response.success = false;
    })
    .then(function () {
      // console.log(
      //   `APIFilesRequest :: Finally :: response:\n ${JSON.stringify(response)}`
      // );
      return response;
    });
};

