import { estethica_img, dunyagoz_img, medical_park_img } from "../img";

export const providersList_ar = [
  {
    id: 1,
    provider_name: "ميديكال بارك Medical Park",
    content_url: "/ar/health-providers/medical-park",
    speciality: { name: "", img: medical_park_img },
    brief:
      "تأسست في عام 1993 ، ميديكال بارك هي في الوقت الحاضر أكبر مجموعة مستشفيات في تركيا...",
    certifications: [
      "وزارة الصحة - جمهورية تركيا",
      "اعتماد اللجنة المشتركة الدولية (JCI)",
      "شهادة نظام إدارة الجودة ISO 9001",
    ],
    excellence_areas: [
      "زرع الأعضاء،",
      "علاج العقم ومركز أطفال الأنابيب",
      "طب الأعصاب",
      "علم الأورام،...",
    ],
    address: "",
    next_available: "Available on Fri, 22 Mar",
    amount: "$300 - $1000",
    lat: -33.847927,
    lng: 150.6517938,
    icons: "default",
    total_review: "17",
    image: medical_park_img,
    rating: 4.3,
    total_rating: 10203,
  },
  {
    id: 2,
    provider_name: "Dunyagoz",
    content_url: "/ar/health-providers/dunyagoz",
    speciality: { name: "", img: dunyagoz_img },
    brief:
      "استحدثت مجموعة مستشفيات Dünyagöz مفهوم مرافق العلاج المتخصص في تركيا في عام 1996 ، ومن حينها تقدم مئات من طرق العلاج المختلفة في جميع فروع طب العيون...",
    certifications: [
      "معتمد من قبل JCI Joint Commission on Accreditation.",
      "ISO 9001 (المنظمة الدولية للتوحيد القياسي): يتم تدقيقها كل عام بواسطة TÜV-SÜD.",
      "يضمن نظام ISO 10002 إرضاء المريض",
      "وزارة الصحة - جمهورية تركيا",
    ],
    excellence_areas: [
      "ليزر وعدسات داخل العين",
      "طول النظر الشيخوخي",
      "أمراض الريتينا",
      "الزرق",
      "صحة عيون الأطفال",
      "سترابيسموس",
      "OCULOPLASTIC",
      "أمراض القرنية",
      "طب الأعصاب ،",
      "أورام العين ، ...",
    ],
    address: "",
    next_available: "Available on Fri, 22 Mar",
    amount: "$300 - $1000",
    lat: -33.847927,
    lng: 150.6517938,
    icons: "default",
    total_review: "17",
    image: dunyagoz_img,
    rating: 4.7,
    total_rating: 8043,
  },
  {
    id: 3,
    provider_name: "Estethica",
    content_url: "/ar/health-providers/estethica",
    speciality: { name: "", img: estethica_img },
    brief:
      "مجموعة Estethica الطبيّة هي مؤسسة صحية رائدة في تركيا تقدّم خدمات عالية الجودة ، وذلك بفضل الجمع بين أحدث الأساليب والمعدات والمبادئ الأخلاقية...",
    certifications: [
      "وزارة الصحة - جمهورية تركيا",
      "تمتلك العيادة براءة اختراع لإنشاء تقنية زرع الشعر DHI Plus Gold",
    ],
    excellence_areas: [
      "الأمراض الجلدية والتجميل الطبي وجراحة التعافي",
      "الأمراض الجلدية والتجميل الطبي وجراحة التعافي",
      "زراعة الشعر",
      "طب الأسنان، صحة الفم، طب أسنان الأطفال،",
      "الطب البديل: الحجامة ، والوخز بالإبر ، ...",
    ],
    address: "",
    next_available: "Available on Fri, 22 Mar",
    amount: "$300 - $1000",
    lat: -33.847927,
    lng: 150.6517938,
    icons: "default",
    total_review: "17",
    image: estethica_img,
    rating: 4.8,
    total_rating: 5991,
  },
];
