import { Country } from "country-state-city";
import { allowedCountries } from 'common';

const getCountriesList = () =>
  allowedCountries.map(countryCode => Country.getCountryByCode(countryCode));

const getCitiesOfCountry = (countryCode) =>
  []

const getCountryOfCode = (countryCode) => Country.getCountryByCode(countryCode);

const CountryCity = {
  getCountriesList,
  getCitiesOfCountry,
  getCountryOfCode,
};

export default CountryCity;
