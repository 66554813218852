import { languages } from "common";
import { useContext } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import { useTranslation } from "react-i18next";
import AppContext from "store/app-store";
// import FlagIcon from "../../../FlagIcon";

import styles from "./MenuLangPicker.module.css";

const getDesktopItem = (lang, changeLang) => {
  return (
    <Dropdown.Item
      key={`mobile-${lang.key}`}
      onClick={() => changeLang(lang.key)}
      className="d-flex justify-content-between"
    >
      <div className="px-2">{lang.name}</div>
      <div className={`px-2 ${styles.flag}`}>
        <img src={lang.flag} alt={lang.name} />
      </div> 
      {/* 
      <div className={`px-2 ${styles.flag}`}>
        <FlagIcon code={lang.flag} />
      </div> 
      */}
    </Dropdown.Item>
  );
};

const getMobileItem = (lang, changeLang, onClick) => {
  return (
    <a
      key={lang.key}
      onClick={() => {
        changeLang(lang.key);
        onClick();
      }}
    >
      {/* <FlagIcon code={lang.flag} /> */}
      <img src={lang.flag} alt="" />
    </a>
  );
};

const MenuLangPicker = ({ onClick, changeLang, isMobile = false }) => {
  const [, i18n] = useTranslation();
  const isHidden = false;
  const { getLang } = useContext(AppContext);

  const getLangItem = (lang) =>
    isMobile
      ? getMobileItem(lang, changeLang, onClick)
      : getDesktopItem(lang, changeLang);

  const mapLanguages = () =>
    languages.map((lang) => getLang() !== lang.key && getLangItem(lang));

  const getMobileMenu = () => (
    <ul className="main-nav align-content-center d-lg-none" hidden={isHidden}>
      <li className="login-link d-flex justify-content-around">
        {mapLanguages()}
      </li>
    </ul>
  );

  const getDesktopMenu = () => (
    <ul className="nav header-navbar-rht" hidden={isHidden}>
      <Dropdown className="nav-item dropdown has-arrow logged-item">
        <Dropdown.Toggle
          variant=""
          id="dropdown-basic"
          style={{ color: "#458fd6", fontSize: "25px" }}
        >
          <i className="fa fa-language" aria-hidden="true"></i>
        </Dropdown.Toggle>
        <Dropdown.Menu
          style={{
            minWidth: "unset",
            direction: getLang() === "ar" ? "rtl" : "ltr",
          }}
        >
          {mapLanguages()}
        </Dropdown.Menu>
      </Dropdown>
    </ul>
  );

  return isMobile ? getMobileMenu() : getDesktopMenu();
};

export default MenuLangPicker;
