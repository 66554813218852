import { estethica_img, dunyagoz_img, medical_park_img } from "../img";

export const providersList_en = [
  {
    id: 1,
    provider_name: "Medical Park",
    content_url: "/en/health-providers/medical-park",
    speciality: { name: "", img: medical_park_img },
    brief:
      "Founded in 1993, Medical Park is nowadays the biggest hospital group in Turkiye...",
    certifications: [
      "Ministry of Health -  Republic of Turkiye",
      "JCI (Joint Commission International) accreditation",
      "ISO 9001 Quality Management System Certificate",
    ],
    excellence_areas: [
      "Organ Transplantation,",
      "Fertility Treatment and IVF Center,",
      "Neurology,",
      "Oncology,...",
    ],
    address: "",
    next_available: "Available on Fri, 22 Mar",
    amount: "$300 - $1000",
    lat: -33.847927,
    lng: 150.6517938,
    icons: "default",
    total_review: "17",
    image: medical_park_img,
    rating: 4.3,
    total_rating: 10203,
  },
  {
    id: 2,
    provider_name: "Dunyagoz",
    content_url: "/en/health-providers/dunyagoz",
    speciality: { name: "", img: dunyagoz_img },
    brief:
      "The Dünyagöz Hospitals Group introduced the concept of specialist treatment facilities in Türkiye in 1996 and offers hundreds of different treatment methods in all branches of ophthalmology...",
    certifications: [
      "Certified by JCI Joint Commission on Accreditation.",
      "ISO 9001 (International Standardization Organization): audited every year by TÜV-SÜD.",
      "The ISO 10002 system, ensuring patient satisfaction.",
      "Ministry of Health - Republic of Turkiye ",
    ],
    excellence_areas: [
      "LASER AND INTRAOCULAR LENS, ",
      "Presbyopia,",
      "RETINA diseases,",
      "GLAUCOMA,",
      "PEDIATRIC EYE HEALTH,",
      "STRABISMUS,",
      "OCULOPLASTIC,",
      "CORNEAL DISEASES,",
      "NEURO OPHTHALMOLOGY,",
      "OCULAR ONCOLOGY, ...",
    ],
    address: "",
    next_available: "Available on Fri, 22 Mar",
    amount: "$300 - $1000",
    lat: -33.847927,
    lng: 150.6517938,
    icons: "default",
    total_review: "17",
    image: dunyagoz_img,
    rating: 4.7,
    total_rating: 8043,
  },
  {
    id: 3,
    provider_name: "Estethica",
    content_url: "/en/health-providers/estethica",
    speciality: { name: "", img: estethica_img },
    brief:
      "Estethica Health Group is a leading health institution in Türkiye with its high-quality aesthetic services, thanks to combining state-of-art methods and equipment with ethical principles. Estethica's 240 qualified medical staff members and surgeons have gained international experience and reputation...",
    certifications: [
      "Ministry of Health - Republic of Turkiye",
      "The clinic owns a patent for creating the DHI Plus Gold hair transplantation technique",
    ],
    excellence_areas: [
      "Dermatology, Medical Aesthetics and Recovery surgery",
      "Dermatology, Medical Aesthetics and Recovery surgery,",
      "Hair Transplantation,",
      "Dentistry, Oral Health, Pediatric Dentistry,",
      "Alternative Medicine: cupping, acupuncture, ...",
    ],
    address: "",
    next_available: "Available on Fri, 22 Mar",
    amount: "$300 - $1000",
    lat: -33.847927,
    lng: 150.6517938,
    icons: "default",
    total_review: "17",
    image: estethica_img,
    rating: 4.8,
    total_rating: 5991,
  },
];
