import React from "react";
import { useTranslation } from "react-i18next";
import { Page } from "common/components";
import MetaTags from "common/MetaTags";
import EstethicaEn from "./estethica-en";
import EstethicaAr from "./estethica-ar";
import DunyagozEn from "./dunyagoz-en";
import DunyagozAr from "./dunyagoz-ar";
import MedicalParkEn from './medical-park-en';
import MedicalParkAr from './medical-park-ar';
import { getDescription } from "../descriptions";

const HealthProvidersDetails = ({ contentId, titleId }) => {
  const [t, i18n] = useTranslation();

  const getTitle = () => t(titleId);

  const metaTags = [
    {
      name: "description",
      content: getDescription(contentId, i18n.language),
    },
  ];

  const renderSwitch = () => {
    switch (contentId) {
      case "estethica":
        return i18n.language === "ar" ? <EstethicaAr /> : <EstethicaEn />;
      case "dunyagoz":
        return i18n.language === "ar" ? <DunyagozAr /> : <DunyagozEn />;
      case "medical-park":
        return i18n.language === "ar" ? <MedicalParkAr /> : <MedicalParkEn />;
      default:
        return null;
    }
  };

  return (
    <Page title={getTitle()}>
      <MetaTags title={getTitle()} tags={metaTags} />
      <div className="content">
        <div className="container">
          <div className="row">
            <div className="col-12">{renderSwitch()}</div>
          </div>
        </div>
      </div>
    </Page>
  );
};

export default HealthProvidersDetails;
