/* eslint-disable   */

import axios from "axios";
import authHeader from "../client/services/auth-header";

export const APIRequest = async ({
  requestBody = {},
  url,
  queryParams = {},
  method,
}) => {
  var config = {
    method: `${method}`,
    url: `${url}`,
    params: { ...queryParams },
    headers: authHeader(),
    data: requestBody,
  };

  const apiResponse = {
    message: "",
    success: false,
    body: {},
    pagingation: {},
  };

  // console.log(`APIRequest :: requestBody: ${JSON.stringify(requestBody)}`);
  // console.log(`APIRequest :: url: ${url}`);
  // console.log(`APIRequest :: queryParams: ${JSON.stringify(queryParams)}`);

  return axios(config)
    .then((res) => {
      // console.log(`APIRequest :: res: ${JSON.stringify(res)}`);
      const data = res?.data;
      apiResponse.message = [data?.responseBody?.message];
      apiResponse.body = data?.responseBody?.content || data?.responseBody;
      apiResponse.pagingation = {
        totalElements: data?.responseBody?.totalElements,
        totalPages: data?.responseBody?.totalPages,
      };
      apiResponse.success = true;
    })
    .catch((error) => {
      // console.log(`error: ${JSON.stringify(error)}`);
      // console.log(`error.response: ${JSON.stringify(error.response)}`);
      const data = !!error?.response?.data ? error?.response?.data : error;
      apiResponse.message = !!data?.responseBody?.message
        ? data?.responseBody?.message
        : data?.message;
      apiResponse.success = false;
    })
    .then(() => {
      // console.log(
      //   `APIRequest :: Finally :: apiResponse:\n ${JSON.stringify(apiResponse)}`
      // );
      return apiResponse;
    });
};