import { APIRequest } from "common";

const Treatment_API_URL = `${process.env.REACT_APP_YMP_API}/api/v1/treatment`;

const getAllTreatments = () => {
  const url = Treatment_API_URL;
  return APIRequest({
    url: url,
    method: "get",
  });
};

const getTreatmentByID = (TreatmentId) => {
  const url = `${Treatment_API_URL}/${TreatmentId}`;
  return APIRequest({
    url: url,
    method: "get",
  });
};

const createTreatment = (data) => {
  const url = Treatment_API_URL;
  return APIRequest({
    url: url,
    method: "post",
    requestBody: { ...data },
  });
};

const updateTreatment = (TreatmentID, data) => {
  const url = `${Treatment_API_URL}/${TreatmentID}`;
  return APIRequest({
    url: url,
    method: "put",
    requestBody: { ...data },
  });
};

const deleteTreatment = (TreatmentID) => {
  const url = `${Treatment_API_URL}/${TreatmentID}`;
  return APIRequest({
    url: url,
    method: "delete",
  });
};

const TreatmentService = {
  getAllTreatments,
  getTreatmentByID,
  createTreatment,
  updateTreatment,
  deleteTreatment,
};

export default TreatmentService;
