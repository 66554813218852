import { createContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useCookies } from "react-cookie";

const LANG_STORAGE_KEY = "i18nextLng";
const LANG_COOKIE_KEY = "i18next";
const DEFAULT_LANG = "en";

const AppContext = createContext({
    getLang: () => {},
    changeLang: () => {},
    isMenuOpen: () => {},
    toggleMenu: () => {},
});

export const AppContextProvider = (props) => {
    const {i18n} = useTranslation();
    const [lang, setLang] = useState(DEFAULT_LANG);
    const [, setCookie] = useCookies([LANG_COOKIE_KEY]);
    const [menuStatus, setMenuStatus] = useState(false);

    useEffect(() => {
        const storedLang = localStorage.getItem(LANG_STORAGE_KEY);
        if (storedLang) {
            setLang(storedLang);
            i18n.changeLanguage(storedLang);
        } else {
            localStorage.setItem(LANG_STORAGE_KEY, DEFAULT_LANG);
        }
    }, [i18n]);

    const getLang = () => lang;
    const changeLang = (lang) => {
        setLang(lang);
        i18n.changeLanguage(lang).then(() => {
            setCookie(LANG_COOKIE_KEY, lang, { path: '/' });
        });
    }

    const isMenuOpen = () => menuStatus;
    const toggleMenu = () => setMenuStatus((currentStatus) => !currentStatus);

    const contextValue = {
        getLang: getLang,
        changeLang: changeLang,
        isMenuOpen: isMenuOpen,
        toggleMenu: toggleMenu,
    }

    return (
        <AppContext.Provider value={contextValue}>
            {props.children}
        </AppContext.Provider>
    );
};

export default AppContext;