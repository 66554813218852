import React from "react";
import { Link, useLocation } from "react-router-dom";
import { is_it_right_for_me } from "../img";
import { providersList_en } from "../health-providers/ProvidersList_en";
import { renderDynamicHeading } from "common/helpers";

const MedicalParkEn = () => {
  const location = useLocation();
  return (
    <div className="terms-content">
      <div className="terms-text">
        <h1>Medical Park</h1>
        <h2>A total of 4.3 stars out of 10203 google reviews.</h2>
        <p>
          Founded in 1993, Medical Park is nowadays the biggest hospital group
          in Turkiye.
        </p>
        <p>
          Medical Park operates in 28 hospitals distributed in 17 cities, with
          more than 14.000 employees, 200 operating theaters, around 5.500 bed
          capacity, and an indoor area of more than 760.000 square meters,
          offering the highest quality diagnosis and treatment utilizing
          state-of-art technology,
        </p>
        <p>
          Certificates: A number of Medical Park branches are internationally
          accredited: JCI accreditation and ISO 9001 Quality Management System
          Certificate.
        </p>
        <p>
          Academic Collaborations, where more than 680 full-time physicians at
          the hospitals sustain academic careers in various universities.
        </p>

        {/* Certifications - Start */}
        {renderDynamicHeading(
          providersList_en,
          "certifications",
          "h3",
          "Certifications",
          location
        )}
        {/* Certifications - End */}

        <h3>Patient Rooms and Suites</h3>
        <ul>
          <li>
            More than 5.500 beds at 28 hospitals located in 17 cities of Turkey.
          </li>
          <li>
            Standard rooms include phone, television, internet access, safe
            shower and a toilet with shampoo and slippers that meet hygiene,
            safety and quality expectations of patients and companions.
          </li>
        </ul>
        <p>
          &ldquo;
          <em>healthcare for all"</em>:
        </p>
        <p>
          Gathering specialists, patient-oriented service approach and
          multi-disciplinary work under one roof, Medical Park Hospitals
          provides comprehensive quality diagnostic and therapeutic services in
          all hospitals.
        </p>
        <h3>Areas of Excellence</h3>
        <ol>
          <div className="terms-text">
            <li>
              Organ Transplantation: Kidney transplant, Liver transplant,
              Pancreas transplant, and Corneal transplant surgeries.
            </li>
            <span>
              The longest successfully running International Liver Transplant
              Center in T&uuml;rkiye belongs to the Medical Park. The hospital
              holds top notch International Standards Certification, awarded the
              name of ''excellence center'' and are among the ''TOP TRANSPLANT
              CENTERS OF THE WORLD''. Medical team consists of the most
              experienced, US and Germany trained Professors who are liver
              transplant experts, and can handle any crucial emergencies that
              might occur during a Transplantation.
            </span>
            <ul>
              <li>
                One of the branches performed the highest number of transplant
                surgeries in the World with 536 organ transplantations in 2011.
                This center performs one third of all the transplantations in
                T&uuml;rkiye, and has also handled the largest number of
                procedures in Europe.
              </li>
              <li>
                The total number of organ transplantation surgeries carried out
                is: Kidney transplantation: 4298, Liver transplantation: 500,
                Pancreas transplantation: 34.
              </li>
              <li>
                550 organ transplantations on average are carried out in a year.
              </li>
              <li>
                The first triple and quadruple cross donor kidney
                transplantations in the history of medicine in T&uuml;rkiye were
                performed at the hospital we represent.
              </li>
              <li>
                Kidney transplantations in T&uuml;rkiye are only carried out in
                the Hospital.
              </li>
              <li>
                There is no difference between the success rates of
                transplantations with tissue compatibility and transplantations
                without tissue compatibility.
              </li>
              <li>
                By-pass and kidney transplantation surgeries were carried out in
                the same session for the first time in the world in the
                Hospital.
              </li>
              <li>Survival rates in the Hospital is higher than in the USA:</li>
              <li>
                3-year patient survival: 97.4% in the Hospital, 94% in the USA,
              </li>
              <li>
                5-year patient survival: 95.7% in the Hospital, 89.4% in the
                USA.
              </li>
            </ul>
          </div>

          <div className="terms-text">
            <li>
              The Bone Marrow Transplantation: program is certified by the
              Turkish Ministry of Health and European Group for Blood and Marrow
              Transplantation. Serving adults and children, the transplantation
              is conducted by a multidisciplinary team comprised of physicians,
              nurses, pharmacists, therapists, social workers and administrators
              from divisions like intensive care, pulmonary medicine, infectious
              diseases, renal medicine among others.
            </li>
            <p>
              Patient rooms and corridors are equipped with a HEPA Filtering
              system, which reduces the risk of airborne infections.
            </p>
            <span>
              The transplantation can be performed by using either the stem
              cells of the patient (autologous) or his/her siblings (allogenic)
              or if the patient does not have a sibling who is histocompatible,
              then the procedure can be conducted by using stem cells donated by
              people other than relatives to international bone marrow banks.
              Furthermore, cord blood transplantations, oriented by the in-vitro
              fertilization method, is one of the factors that differentiates us
              from others.
            </span>
          </div>

          <div className="terms-text">
            <li>
              Fertility Treatment and IVF Center: Medical Park adopts a
              patient-oriented approach. An experienced team of gynecologist,
              embryologist, medical genetic specialist, urologist, psychologist,
              biologist and nurses and well-equipped in vitro fertilization
              laboratory aim to increase chance of conception in infertile
              couples.
            </li>
            <p>
              The Medical Park encompasses Genetic Diagnosis Laboratory,
              separate operating rooms, as well as andrology and embryology
              laboratories, offering a wide variety of Infertility Treatments
              for men and women with cutting edge technology in infertility
              treatments.
            </p>
            <span>
              The high success rate of the center is above the rates reported by
              reputable clinics in Europe and equals those in the USA, with
              accreditations and affiliations in the field to testify.
            </span>
          </div>

          <div className="terms-text">
            <li>
              The Department of Neurology offers a full range of specialized,
              advanced, and all inclusive neurological services. The hospital's
              Internationally recognized specialists are experts in diagnosing
              and treating the most complex brain and spinal disorders: Stroke,
              Headache, Epilepsy, Movement disorders, Memory disorders,
              Hydrocephalus, Alzheimer's disease, Sleep disorders, and
              Neuromuscular problems. Among the World's 10 Most Excellent
              Centers in Parkinson's Disease Surgery, the center applies an
              advanced method in surgical treatment of Parkinson's Disease and
              many movement disorders with a success rate of 80%.
            </li>
            <ul>
              <li>
                Oncology: The first private Oncology Hospital in Turkey. <br />
                The Medical Park Oncology Center has gained the name of ''Center
                of Excellence'' for the following areas, within the framework of
                the constant international recognition of the hospital's
                advanced distinguished methods: ONCOLOGICAL SURGERY, PEDIATRIC
                ONCOLOGY, GYNECOLOGIC ONCOLOGY, UROONCOLOGY, MEDICAL ONCOLOGY,
                RADIATION ONCOLOGY: Radiotherapy (Linac, Hipec), Tomotherapy,
                Gamma Knife.
              </li>
            </ul>
          </div>

          <div className="terms-text">
            <li>
              Obesity: Medical Park diagnoses the problems to help avoid health
              problems induced by obesity including: Insulin resistance, Type 2
              Diabetes, Gall bladder diseases, Several types of cancer, Skin and
              fungal infections, Musculo-skeletal system problems, Sleep apnea
              among many other.
            </li>
            <ul>
              <li>
                Medical Park uses advanced methods in the treatment of obesity:
                Medical nutrition (diet), Exercise therapy, Pharmacologic
                treatment, Obesity Surgery (bariatric surgery).
              </li>
            </ul>
            <ul>
              <li>
                Methods used in obesity surgery: Sleeve Gastrectomy, Gastric
                By-pass Surgeries, Duodenal Switch Surgery.
              </li>
            </ul>
          </div>

          <div className="terms-text">
            <li>
              Within Cardiology and Cardiac Surgery area, the departments are
              comprised of Coroner Intense Care units, Cardiology Policlinics
              and a Cardiology Laboratory where non-interventional examinations
              (EKG, effort test, echocardiography, Holter, ambulatory blood
              pressure control) are carried out.
            </li>
            <p>
              With the experienced staff specialists, cardiac anesthesia team
              and fully equipped intensive care unit Medical Park has achieved
              high success rates of surgeries.
            </p>
            <ul>
              <li>
                Cardiovascular surgery procedures performed at our hospitals:
                CGM Test, ABI Test, Radial Angiography, Coronary Angiography,
                Coronary By-Pass, Heart Valve (Aorta, Mitral, Tricuspid and
                Pulmonary) repair and replacement surgeries, Heart Valve
                Surgery, Surgeries for the Aneurysm of Aorta (Repair of aneurysm
                of ascending aorta and aortic arc), Repair of Left Ventricular
                Aneurysm, Arterial and venous grafting for by-pass through small
                incisions, Cardiac Surgeries with Small Incisions, Removal of
                Heart Tumors, CVS - Peripheral Vascular Surgery, Stroke Surgery,
                Minimally Invasive Cardiovascular Surgery, Treatment of Varicose
                Veins, and Venous Valve Repairs.
              </li>
            </ul>
            <ul>
              <li>
                Venous Valve Repairs: This method requires substantial
                experience and preferred when advanced stage leakages in deep
                veins lead to swelling, pain and wound on legs. Valve repair is
                performed in pioneering healthcare facilities worldwide such as
                the Medical Park Cardiovascular Surgery Clinic.
              </li>
            </ul>
          </div>

          <div className="terms-text">
            <li>
              The Medical Park's Orthopedics and Traumatology Center is
              internationally- recognized with doctors and surgeons who are
              trusted by professional sports clubs including Fenerbahce,
              Galatasaray, Trabzonspor and the players of these teams.
            </li>
            <span>
              With the academic collaborations, Medical Park is one of the top
              research and treatment centers in: Bone and Joint Surgery -
              Arthroplasty (Hip Replacement) - Arthroplasty (Knee Prosthesis) -
              Concussion - Foot and Ankle - General Orthopaedics and Fracture
              Care - Hand and Upper Extremity - Joint Replacement - Orthopedic
              Trauma - Pediatric Orthopedics - Rehabilitation and Physical
              Therapy - Sarcoma Care - Shoulder Care - Spine Center - Sports
              Medicine.
            </span>
          </div>

          <div className="terms-text">
            <li>
              In the Medical Park's Nuclear Medicine Center, expert physicians
              and qualified technologists use very small amounts of radioactive
              isotopes (or tracers) to help diagnose and treat a range of
              diseases such heart disease or cancer. Nuclear medicine helps
              physicians to get information on body functions part by part.
            </li>
            <p>
              Areas covered by nuclear medicine in the Medical Park include Bone
              Studies, Cardiac Studies, Pulmonary Studies, Thyroid Studies,
              Parathyroid Studies, Lymph Node Studies, Gastric/ Abdominal
              Studies, Hepatobiliary/Liver Studies, Renal/Kidney Studies, Tumor
              Studies, Brain Studies, Central Nervous System, Abscess/Infection
              Studies, Non-Thyroid Therapies.
            </p>
            <span>
              Medical Park Radiology Team consists of specially trained experts
              in obtaining and interpreting medical images collected by using
              x-rays or radioactive substances (nuclear medicine) or by other
              means such as sound waves or the body's natural magnetism. These
              experts will interpret your tests and provide complete information
              for your doctors.
            </span>
          </div>

          <div className="terms-text">
            <li>
              Stroke: The most important recent development is &ldquo;Stroke
              Centers/ Units"; When stroke patients are admitted to a routine or
              a general neurology clinic, the risks for mortality and disability
              will be higher compared to admissions to stroke units.
            </li>
            <p>
              * Treatment and care protocols of modern stroke management
              include, Intravenous thrombolysis, Endovascular treatments,
              Intra-arterial Thrombolysis, Mechanical Removal of Clot
              (Thrombectomy).
            </p>
            <span>
              * Surgical Treatments include: Endarterectomy, Interventional
              Treatments: Angioplasty or Stent implantation methods.
            </span>
          </div>

          <div className="terms-text">
            <li>
              Hair Transplantation: With 15 Years Of Experience In Hair
              Transplantation, hair transplantation methods such as follicular
              unit extraction (FUE)anddirect hair implantation (DHI)arewidely
              applied today, and can give very successful results when applied
              by experienced clinicians.
            </li>
            <p>
              The technique to be practiced is determined by the characteristics
              of the patient's skin and hair and consequently operate the
              correct technique. During the pre-meeting, the technique to be
              implemented and the reasons underlying this preference are
              discussed in detail and are thus decided.
            </p>
            <span>
              Hair transplantation is carried out under the supervision of
              plastic surgeons by the hair transplantation experts who possess
              years of experience.
            </span>
          </div>
        </ol>
      </div>
    </div>
  );
};

export default MedicalParkEn;
