import React from "react";
import { Link, useLocation } from "react-router-dom";
import { is_it_right_for_me } from "../img";
import { renderDynamicHeading } from "common/helpers";
import { providersList_ar } from "../health-providers/ProvidersList_ar";

const MedicalParkAr = () => {
  const location = useLocation();
  return (
    <div className="terms-content">
      <div className="terms-text">
        <h1>ميديكال بارك Medical Park</h1>
        <h2>مع متوسط التقييمات 4.3 من 10.203 تقييمات Google</h2>
        <p>
          تأسست في عام 1993 ، ميديكال بارك هي في الوقت الحاضر أكبر مجموعة
          مستشفيات في تركيا.
        </p>
        <p>
          تعمل ميديكال بارك في 28 مستشفى موزعة في 17 مدينة ، مع أكثر من 14.000
          موظف ، و 200 غرفة عمليات ، وسعة حوالي 5.500 سرير ، ومنطقة داخلية تزيد
          عن 760.000 متر مربع ، وتقدم أعلى مستويات الجودة في التشخيص والعلاج
          باستخدام أحدث التقنيات والتكنولوجيا.
        </p>
        <p>الشهادات</p>
        <p>
          تم اعتماد عدد من فروع ميديكال بارك دوليًا: اعتماد JCI وشهادة نظام
          إدارة الجودة ISO 9001 .
        </p>
        <p>التعاون الأكاديمي</p>
        <p>
          يشغل أكثر من 680 طبيبًا متفرغًا في المستشفيات وظائف أكاديمية في جامعات
          مختلفة.
        </p>
        <h2>غرف وأجنحة المرضى</h2>
        <p>
          تحتوي الغرف العاديّة على الهاتف والتلفاز والإنترنت والاستحمام الآمن
          والحمام مع كافة احتياجات النظافة التي تلبي معايير السلامة والجودة
          للمرضى والمرافقين.
        </p>
        <p>"الرعاية الصحية للجميع"المرضى</p>
        <p>
          من خلال ضمّها المتخصصين الذين يعملون بتكاتف ضمن نظام مبنيّ على حاجة
          المرضى تحت سقف واحد فإنّ مستشفيات ميديكال بارك تقوم بتقديم الخدمات
          التشخيصية والعلاجية الشاملة عالية الجودة في جميع فروعها.
        </p>
        <br />
        <br />
        {/* Certifications - Start */}
        {renderDynamicHeading(
          providersList_ar,
          "certifications",
          "h2",
          "الشهادات",
          location
        )}
        {/* Certifications - End */}
        <h1 className="mt-4">مجالات التميز</h1>
        <ol className="mt-n5">
          <li>
            زراعة الأعضاء: زراعة الكلى ، وزراعة الكبد ، وزراعة البنكرياس ،
            وجراحات زراعة القرنية.
          </li>
          <p>
            <ul>
              <li>
                يعتبر مركز زراعة الكبد التابع لميديكال بارك المركز الدولي الأقدم
                من حيث العمل بنجاح في تركيا.
              </li>
              <li>
                تحمل المستشفى شهادة المعايير الدولية من الدرجة الأولى ، وتم
                منحها اسم "مركز الامتياز" وهي من بين "أفضل مراكز زراعة الأعضاء
                في العالم".
              </li>
              <li>
                يتكون الفريق الطبي من الأساتذة الأكثر خبرة والحاصلين على التدريب
                في الولايات المتحدة وألمانيا.
              </li>
              <li>
                المتخصصون في المستشفى خبراء في زراعة الكبد ، ويمكنهم التعامل مع
                أي حالات طارئة حرجة قد تحدث أثناء عملية الزراعة.
              </li>
              <li>
                أجرى أحد فروع المستشفى أكبر عدد عمليات زراعة الأعضاء في العالم
                في عام 2011حيث بلغ عدد عمليات زراعة الأعضاء 536. يقوم هذا المركز
                بإجراء ثلث جميع عمليات زراعة الأعضاء في تركيا ، كما قام بإجراء
                أكبر عدد من العمليات في أوروبا.
              </li>
              <li>
                إجمالي عدد عمليات زراعة الأعضاء التي تمّ إجراؤها: زراعة الكلى:
                4298 ، زراعة الكبد: 500 ، زراعة البنكرياس: 34.
              </li>
              <li>يتم إجراء 550 عملية زراعة أعضاء في المتوسط في السنة.</li>
              <li>
                تمّ إجراء أول عملية زراعة كلى ثلاثية ورباعية من متبرعين في تاريخ
                الطب في تركيا في ميديكال بارك.
              </li>
              <li>
                يتم إجراء عمليات زراعة الكلى في تركيا فقط في ميديكال بارك.
              </li>
              <li>
                لا يوجد فرق بين معدلات نجاح عمليات الزراعة مع توافق الأنسجة
                وعمليات الزراعة بدون توافق الأنسجة.
              </li>
              <li>
                أجريت جراحات زراعة الكلى في نفس الجلسة ولأول مرة على مستوى
                العالم في ميديكال بارك.
              </li>
              <li>
                معدلات البقاء على قيد الحياة في المستشفى أعلى منها في الولايات
                المتحدة:
                <ul>
                  <li>
                    بقاء المريض لمدة 3 سنوات: 97.4٪ في ميديكال بارك ، 94٪ في
                    الولايات المتحدة ،
                  </li>
                  <li>
                    بقاء المريض لمدة 5 سنوات: 95.7٪ في ميديكال بارك، 89.4٪ في
                    الولايات المتحدة.
                  </li>
                </ul>
              </li>
            </ul>
          </p>
          <p>
            <li>
              برنامج زراعة النخاع العظمي معتمد من قبل وزارة الصحة التركية
              والمجموعة الأوروبية لزراعة الدم والنخاع ويخدم البالغين والأطفال.
              يتمّ إجراء عملية الزراعة من قبل فريق متعدد التخصصات يتألف من
              الأطباء والممرضات والصيادلة والمعالجين والأخصائيين الاجتماعيين
              والإداريين من أقسام مثل العناية المركزة وطب الرئة والأمراض المعدية
              وطب الكلى وغيرها.
            </li>
            <ul>
              <li>
                تم تجهيز غرف المرضى والممرات بنظام ترشيح HEPA ، مما يقلل من
                مخاطر العدوى المنقولة عبر الهواء.
              </li>
            </ul>
            <ul>
              <li>
                يمكن إجراء عملية الزراعة باستخدام إما الخلايا الجذعية للمريض
                (ذاتي) أو إخوته / إخوتها (خيفي) أو إذا لم يكن لدى المريض شقيق
                متوافق مع الأنسجة ، فيمكن إجراء العملية باستخدام الخلايا الجذعية
                المتبرع بها من قبل أشخاص بخلاف الأقارب لبنوك نخاع العظام
                الدولية. علاوة على ذلك ، فإن عمليات زراعة دم الحبل السري ،
                الموجهة بطريقة الإخصاب في المختبر ، هي أحد العوامل التي تميزنا
                عن الآخرين.
              </li>
            </ul>
          </p>
          <p>
            <li>مركز علاج الخصوبة وأطفال الأنابيب:</li>
            <p>
              تتبنى ميديكال بارك نهجًا موجهًا حسب حاجة المرضى. يهدف الفريق ذو
              الخبرة من أطباء أمراض النساء وعلم الأجنة وأخصائي الوراثة الطبية
              وأخصائي المسالك البولية والأخصائي النفسي وعالم الأحياء والممرضات
              ومختبر الإخصاب في المختبر المجهز تجهيزًا جيدًا إلى زيادة فرصة
              الحمل لدى الأزواج المصابين بالعقم.
            </p>
            <ul>
              <li>
                تضم ميديكال بارك مختبر التشخيص الجيني ، وغرف عمليات منفصلة ،
                بالإضافة إلى مختبرات الذكورة وعلم الأجنة ، وتقدم مجموعة متنوعة
                من علاجات العقم للرجال والنساء مع أحدث التقنيات في علاجات العقم.
              </li>
              <li>
                معدل النجاح المرتفع للمركز أعلى من المعدلات التي أبلغت عنها
                العيادات ذات السمعة الطيبة في أوروبا ويساوي تلك الموجودة في
                الولايات المتحدة الأمريكية ، مع الاعتمادات والانتسابات في هذا
                المجال شاهدةً على ذلك.
              </li>
            </ul>
          </p>
          <p>
            <li>
              يقدم قسم طب الأعصاب مجموعة كاملة من الخدمات العصبية المتخصصة
              والمتقدمة والشاملة. المتخصصون المعترف بهم دوليًا في المستشفى هم
              خبراء في تشخيص وعلاج أكثر اضطرابات الدماغ والعمود الفقري تعقيدًا:
              السكتة الدماغية ، والصداع ، والصرع ، واضطرابات الحركة ، واضطرابات
              الذاكرة ، واستسقاء الرأس ، ومرض الزهايمر ، واضطرابات النوم ،
              والمشاكل العصبية العضلية. &ndash; يحتوي مستشفى ميديكال بارك على
              أحد أكثر 10 مراكز متميّزة في العالم في جراحة مرض الباركنسون. يطبق
              المركز طريقة متقدمة في العلاج الجراحي لمرض الباركنسون والعديد من
              اضطرابات الحركة بنسبة نجاح تصل إلى 80٪.
            </li>
          </p>
          <li> الأورام:</li>
          <p>يضمّ ميديكال بارك أول مستشفى خاص للأورام في تركيا.</p>
          <p>
            في إطار الاعتراف الدولي المستمر بأساليب المستشفى المتميزة المتقدمة
            حصل مركز ميديكال بارك للأورام على اسم "مركز التميز '' في المجالات
            التالية: جراحة الأورام ، طب الأورام عند الأطفال ، الأورام النسائية ،
            أورام الجهاز البولي ، الأورام الطبية ، علاج الأورام الإشعاعي: العلاج
            الإشعاعي (Linac ، Hipec) ، Tomotherapy ، Gamma Knife..
          </p>
          <p>
            <li>السمنة:</li>
            <p>
              - يقوم مستشفى ميديكال بارك بتشخيص المشاكل الطّبيّة للمساعدة في
              تجنب المشاكل الصحية التي تسببها السمنة بما في ذلك: مقاومة
              الأنسولين ، داء السكري من النوع 2 ، أمراض المرارة ، عدة أنواع من
              السرطان ، التهابات الجلد والفطريات ، مشاكل الجهاز العضلي الهيكلي ،
              توقف التنفس أثناء النوم.
            </p>
            <p>
              - يستتخدم مستشفى ميديكال بارك طرقًا متقدمة في علاج السمنة: التغذية
              الطبية (النظام الغذائي) ، العلاج بالتمارين الرياضية ، العلاج
              الدوائي ، جراحة السمنة.
            </p>
            <ul>
              <li>
                الطرق المستخدمة في جراحة السمنة: تكميم المعدة ، جراحة تحويل مسار
                المعدة ، جراحة تحويل الاثني عشر.
              </li>
            </ul>
          </p>
          <li>قسم أمراض القلب وجراحة القلب:</li>
          <p>
            يحتوي القسم على وحدات العناية المركزة لأمراض القلب وعيادات طب القلب
            ومختبر أمراض القلب حيث يتم إجراء الفحوصات غير التداخلية ( EKG
            واختبار الجهد وتخطيط صدى القلب وهولتر ومراقبة ضغط الدم المتنقل).
          </p>
          <p>
            حققت ميديكال بارك معدلات نجاح عالية في العمليات الجراحية من خلال
            الأخصائيين ذوي الخبرة وفريق التخدير القلبي ووحدة العناية المركزة
            المجهزة تجهيزًا كاملاً.
          </p>
          <ul>
            <li>
              ضمن جراحة القلب والأوعية الدموية في ميديكال بارك يتمّ إجراء:
              اختبار CGM ، واختبار ABI ، وتصوير الأوعية الشعاعية ، وتصوير
              الأوعية التاجية ، والممر الجانبي للشريان التاجي ، وجراحات إصلاح
              واستبدال الصمامات القلبية ، وجراحة صمام القلب ، وجراحات تمدد
              الأوعية الدموية من الشريان الأورطي (إصلاح تمدد الأوعية الدموية في
              الأبهر الصاعد والقوس الأبهري) ، وإصلاح تمدد الأوعية الدموية في
              البطين الأيسر ، وتطعيم الشرايين والوريد من أجل المرور عبر شقوق
              صغيرة ، وجراحات القلب مع شقوق صغيرة ، وإزالة أورام القلب ، وجراحة
              الأوعية الدموية الطرفية ، والسكتة الدماغية الجراحة وجراحة القلب
              والأوعية الدموية طفيفة التوغل وعلاج الدوالي وإصلاح الصمامات
              الوريدية.
            </li>
            <li>
              إصلاح الصمامات الوريدية: تتطلب هذه الطريقة خبرة كبيرة ويتم إجراؤها
              في مرافق الرعاية الصحية الرائدة في العالم مثل عيادة ميديكال بارك
              لجراحة القلب والأوعية الدموية.
            </li>
          </ul>
          <li>
            مركز جراحة العظام والكسور في ميديكال بارك معترف به دوليًا من قبل
            الأطباء والجراحين الذين تثق بهم الأندية الرياضية المحترفة بما في ذلك
            فناربهشه وغلطة سراي وطرابزون سبور ولاعبي هذه الفرق.
          </li>
          <p>
            من خلال التعاون الأكاديمي ، تعدّ ميديكال بارك واحدة من أفضل مراكز
            البحث والعلاج في: جراحة العظام والمفاصل - تقويم المفاصل (استبدال
            مفصل الورك) - تقويم المفاصل (تركيبات الركبة) - الارتجاج - القدم
            والكاحل - جراحة العظام العامة والعناية بالكسور - اليد والجزء العلوي
            الأطراف - استبدال المفاصل - إصابات العظام - جراحة عظام الأطفال -
            إعادة التأهيل والعلاج الطبيعي - العناية بالساركوما - العناية بالكتف
            - مركز العمود الفقري - الطب الرياضي.
          </p>
          <li>
            في مركز الطب النوويفي ميديكال بارك ، يستخدم الأطباء الخبراء
            والتقنيون المؤهلون كميات صغيرة جدًا من النظائر المشعة (أو أدوات
            التتبع) للمساعدة في تشخيص وعلاج مجموعة من الأمراض مثل أمراض القلب أو
            السرطان. يساعد الطب النووي الأطباء في الحصول على معلومات عن وظائف
            الجسم جزءًا تلو الآخر.
          </li>
          <p>
            تشمل المجالات التي يغطيها الطب النووي في ميديكال بارك دراسات العظام
            ، ودراسات القلب ، والدراسات الرئوية ، ودراسات الغدة الدرقية ،
            ودراسات الغدة الجار درقية ، ودراسات العقدة الليمفاوية ، ودراسات
            المعدة / البطن ، ودراسات الكبد ، ودراسات الكلى، ودراسات الأورام ،
            ودراسات الدماغ ، الجهاز العصبي المركزي ، دراسات الخراج / العدوى ،
            العلاجات غير الدرقية.
          </p>
          <p>
            - يتكون فريق ميديكال بارك للأشعة من خبراء مدربين تدريباً خاصاً في
            الحصول على الصور الطبية التي تم جمعها وتفسيرها باستخدام الأشعة
            السينية أو المواد المشعة (الطب النووي) أو بوسائل أخرى مثل الموجات
            الصوتية أو المغناطيسية الطبيعية للجسم.
          </p>
          <li>السكتة الدماغية:</li>
          <p>
            أهم تطور حديث في هذا المجال هو "مراكز / وحدات السكتة الدماغية" ؛
            عندما يتم قبول مرضى السكتة الدماغية في عيادة روتينية أو عامة لطب
            الأعصاب ، فإن مخاطر الوفيات والإعاقة ستكون أعلى مقارنة بالقبول في
            وحدات السكتة الدماغية.
          </p>
          <p>
            * تشمل بروتوكولات العلاج والرعاية الخاصة بإدارة السكتة الدماغية
            الحديثة ، انحلال الخثرة الوريدي ، وعلاجات الأوعية الدموية الداخلية ،
            وانحلال الخثرة داخل الشرايين ، والإزالة الميكانيكية للجلطة (استئصال
            الخثرة).
          </p>
          <p>
            * تشمل العلاجات الجراحية: استئصال باطنة الشريان ، والعلاجات
            التداخلية: رأب الأوعية الدموية أو طرق زراعة الدعامة.
          </p>
          <li>زراعة الشعر:</li>
          <p>
            مع 15 عامًا من الخبرة في زراعة الشعر ، يتم تطبيق طرق زراعة الشعر مثل
            اقتطاف وحدة البصيلات (FUE) وزراعة الشعر المباشر (DHI) على نطاق واسع
            اليوم ، ويمكن أن تعطي نتائج ناجحة جدًا عند تطبيقها من قبل الأطباء
            ذوي الخبرة.
          </p>
          <p>
            يتم تحديد التقنية التي يتم ممارستها من خلال خصائص جلد المريض وشعره ،
            وبالتالي يتم استخدام التقنية الصحيحة. خلال الاجتماع التمهيدي ، تتم
            مناقشة التقنية التي سيتم تنفيذها والأسباب الكامنة وراء هذا التفضيل
            بالتفصيل وبالتالي يتم تحديدها.
          </p>
          <p>
            تتم زراعة الشعر تحت إشراف جراحي التجميل من قبل خبراء زراعة الشعر
            الذين يمتلكون سنوات من الخبرة.
          </p>
        </ol>
      </div>
    </div>
  );
};

export default MedicalParkAr;
