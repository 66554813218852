export const getDescription = (contentId, language) => {
  const pageDescription = descriptions[contentId];
  if (pageDescription) {
    return pageDescription[language.split("-")[0]] || "";
  }
  return "";
};

export const descriptions = {
  "about-us": {
    en: "Your Medical Portal is a comprehensive platform providing medical tourism services. Learn more about us and how we can assist you with your healthcare journey.",
    ar: "Your Medical Portal هي منصة شاملة توفر خدمات السياحة الطبية. تعرّف علينا وكيف يمكننا مساعدتك في رحلتك الصحية.",
  },
  "why-medical-tourism": {
    en: "Discover the advantages of medical tourism and why it can be a great option for your healthcare needs. Learn about the benefits, popular destinations, and available medical treatments.",
    ar: "اكتشف مزايا السياحة الطبية ولماذا يمكن أن تكون خيارًا رائعًا لاحتياجاتك الصحية. تعرف على الفوائد والوجهات السياحية الشهيرة والعلاجات الطبية المتاحة.",
  },
  "is-it-right-for-me": {
    en: "Find out if medical tourism is the right choice for you. Explore the considerations, benefits, and challenges of traveling abroad for medical treatments and procedures.",
    ar: "تعرف على ما إذا كانت السياحة الطبية هي الخيار الصحيح بالنسبة لك. استكشف الاعتبارات والفوائد والتحديات المتعلقة بالسفر إلى الخارج لتلقي العلاجات والإجراءات الطبية.",
  },
  "essentials-to-pack": {
    en: "Discover the essential items you should pack for your medical tourism journey. Learn about the important documents, medical records, and other items you may need during your trip.",
    ar: "اكتشف العناصر الأساسية التي يجب عليك حزمها لرحلتك في السياحة الطبية. تعرف على الوثائق الهامة والسجلات الطبية وغيرها من العناصر التي قد تحتاجها خلال رحلتك.",
  },
  "about-turkey": {
    en: "Discover interesting facts and information about Turkey, a popular destination for medical tourism. Learn about the country's culture, attractions, and healthcare services.",
    ar: "اكتشف حقائق ومعلومات مثيرة حول تركيا، وجهة مشهورة للسياحة الطبية. تعرف على ثقافة البلد والمعالم السياحية والخدمات الصحية المتاحة.",
  },
  "why-turkey": {
    en: "Explore why Turkey is a preferred choice for medical tourism. Discover the country's advanced medical facilities, skilled healthcare professionals, and affordable treatment options.",
    ar: "استكشف لماذا تعتبر تركيا الخيار المفضل للسياحة الطبية. اكتشف المرافق الطبية المتقدمة في البلاد والمهنيين الصحيين الماهرين وخيارات العلاج الميسرة.",
  },
  "istanbul": {
    en: "Explore Istanbul in Turkey and enjoy an exceptional medical tourism experience. Discover the key tourist areas and available healthcare services.",
    ar: "اكتشف مدينة إسطنبول في تركيا واستمتع بتجربة سياحة طبية استثنائية. تعرف على المناطق السياحية الرئيسية والخدمات الصحية المتاحة.",
  },
  "antalya": {
    en: "Discover the beautiful city of Antalya in Turkey and experience top-quality medical tourism. Learn about the popular tourist attractions and the healthcare services offered.",
    ar: "اكتشف مدينة أنطاليا الجميلة في تركيا واستمتع بتجربة سياحة طبية عالية الجودة. تعرف على المعالم السياحية الشهيرة والخدمات الصحية المقدمة.",
  },
  "health-providers": {
    en: "Find and connect with health providers and medical services in your area. Your Medical Portal makes it easy to search for doctors, clinics, hospitals, and other healthcare professionals.",
    ar: "ابحث وتواصل مع مقدمي الرعاية الصحية والخدمات الطبية في منطقتك. بوابتك الطبية تجعل من السهل البحث عن الأطباء والعيادات والمستشفيات وغيرها من المتخصصين في مجال الرعاية الصحية.",
  },
  "estethica": {
    en: "Learn about Estethica, a leading healthcare provider offering high-quality medical services. Discover their specialties, facilities, and patient testimonials.",
    ar: "تعرف على إستيثيكا، مقدم الرعاية الصحية الرائد الذي يقدم خدمات طبية عالية الجودة. اكتشف تخصصاتهم ومرافقهم وشهادات المرضى.",
  },
  "dunyagoz": {
    en: "Discover Dunyagoz, a renowned eye care center providing a wide range of ophthalmology services. Learn about their expertise, technologies, and success stories.",
    ar: "اكتشف دنياغوز، مركز الرعاية العيون الشهير الذي يقدم مجموعة واسعة من خدمات طب العيون. تعرف على خبرتهم وتقنياتهم وقصص النجاح.",
  },
  "medical-park": {
    en: "Explore Medical Park, a leading hospital network offering comprehensive healthcare services. Learn about their state-of-the-art facilities, medical specialties, and patient-centered care.",
    ar: "استكشف ميديكال بارك، شبكة مستشفيات رائدة تقدم خدمات رعاية صحية شاملة. تعرف على مرافقهم الحديثة والتخصصات الطبية والرعاية المركزة على المريض.",
  },
  "faq": {
    en: "Find answers to frequently asked questions about medical tourism. Learn about the process, benefits, destinations, and more.",
    ar: "اعثر على إجابات للأسئلة المتكررة حول السياحة الطبية. تعرف على العملية والفوائد والوجهات والمزيد.",
  },
  "privacy-policy": {
    en: "Learn about our privacy policy and how we handle your personal information. Understand your rights and how we protect your data.",
    ar: "تعرّف على سياسة الخصوصية وكيفية التعامل مع المعلومات الشخصية. افهم حقوقك وكيف نحمي بياناتك.",
  },
  "what-to-expect": {
    en: "Discover what to expect during your medical journey. From initial consultations to post-treatment care, we provide comprehensive information to help you prepare.",
    ar: "اكتشف ما يمكنك توقعه خلال رحلتك الطبية. نقدم معلومات شاملة حول الاستشارات الأولية والرعاية بعد العلاج لمساعدتك في التحضير.",
  },
  "terms-and-conditions": {
    en: "Read and understand our terms and conditions. Learn about the rules and guidelines for using our services and website.",
    ar: "اقرأ وتعرف على الشروط والأحكام الخاصة بنا. تعرف على القواعد والإرشادات لاستخدام خدماتنا وموقعنا.",
  },
};
