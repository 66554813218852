import servive1Banner from "../client/assets/images/service1.jpg";
import servive2Banner from "../client/assets/images/service2.jpg";
import servive3Banner from "../client/assets/images/service3.jpg";
import servive4Banner from "../client/assets/images/service4.jpg";
import servive5Banner from "../client/assets/images/service5.jpg";

const labels = {
  flight_AdultTravellers: "Number Of Adult Travellers",
  flight_InfantTravellers: "Number Of Infant Travellers",
  flight_Class: "Flight Class",
  flight_OtherPreferences: "Other Preferences",

  accomodation_Proximity: "How far from the doctor?",
  accomodation_Stars: "Hotel stars",
  accomodation_Guests: "Number of guests",
  accomodation_Rooms: "Number of rooms",
  accomodation_Beds: "Number of beds",
  accomodation_OtherPreferences: "Other Preferences",

  transport_CarClass: "Car Class",
  transport_Biscuit: "Do you need internet (biscuit)?",

  translation_LangFrom: "Which language do you want to tanslante from?",
};

const questions = [
  {
    title: "Flight",
    banner: servive1Banner,
    text: "Would you like to have a flight?",
    selectionCondition: "isFlightSelected",
    fields: [
      {
        name: "flight_AdultTravellers",
      },
      {
        name: "flight_InfantTravellers",
      },
      { name: "flight_Class" },
      {
        name: "flight_OtherPreferences",
      },
    ],
  },
  {
    title: "Accommodation",
    banner: servive2Banner,
    text: "Do you need accommodation?",
    selectionCondition: "isAccommodationSelected",
    fields: [
      { name: "accomodation_Proximity" },
      { name: "accomodation_Stars" },
      { name: "accomodation_Guests" },
      { name: "accomodation_Rooms" },
      { name: "accomodation_Beds" },
      { name: "accomodation_OtherPreferences" },
    ],
  },
  {
    title: "Transport & Connectivity",
    banner: servive3Banner,
    text: "How about Transportation & Connectivity?",
    selectionCondition: "isTransportSelected",
    fields: [{ name: "transport_CarClass" }, { name: "transport_Biscuit" }],
  },
  {
    title: "Translation",
    banner: servive4Banner,
    text: "Do you need a translator?",
    selectionCondition: "isTranslationSelected",
    fields: [
      {
        name: "translation_LangFrom",
      },
    ],
  },
  {
    title: "Tourist Activity",
    banner: servive5Banner,
    text: "How about tourist activities?",
    selectionCondition: "isTouristSelected",
    fields: [{ name: "" }],
  },
];
export { questions, labels };

