import React from "react";
import { Link, useLocation } from "react-router-dom";
import { is_it_right_for_me } from "../img";
import { renderDynamicHeading } from "common/helpers";
import { providersList_ar } from "../health-providers/ProvidersList_ar";

const EstethicaAr = () => {
  const location = useLocation();
  return (
    <div className="terms-content">
      <div className="terms-text">
        <h1>Estethica</h1>
        <h3>إجمالي التقييمات 4.8 نجمة من 6000 تقييماتGoogle&nbsp;</h3>
        <h3>جودة</h3>
        <p>
          مجموعة Estethica الطبيّة هي مؤسسة صحية رائدة في تركيا تقدّم خدمات
          عالية الجودة ، وذلك بفضل الجمع بين أحدث الأساليب والمعدات والمبادئ
          الأخلاقية.
        </p>
        <ul>
          <li>
            تمتلك العيادات براءة اختراع لإنشاء تقنية زرع الشعر DHI Plus Gold .
          </li>
        </ul>
        {/* Certifications - Start */}
        {renderDynamicHeading(
          providersList_ar,
          "certifications",
          "h3",
          "الشهادات",
          location
        )}
        {/* Certifications - End */}

        {/* excellence_areas - Start */}
        {renderDynamicHeading(
          providersList_ar,
          "excellence_areas",
          "h3",
          "مجالات التميز",
          location
        )}
        {/* excellence_areas - End */}
        <h3>مميّزات</h3>
        <ul>
          <li>
            اكتسب أعضاء الطاقم الطبي والجراحي المؤهل في Estethica خبرة وسمعة
            دولية ، والذين يزيد عددهم عن 200. حيث الغالبية العظمى منهم أعضاء في
            الجمعية التركية للجراحة الترميمية والتجميلية.
          </li>
          <li>
            بشعار "الجمال الصحي" ، تجري Estethica أكثر من 2500 عملية زراعة شعر
            سنويًا.
          </li>
          <li>
            يتمّ إيلاء اهتمام خاص لإعداد المريض قبل الجراحة ، وكذلك لمراقبة
            حالته بعد العملية.
          </li>
          <li>يتمّ إجراء "تقييم رضا المرضى" بانتظام.</li>
          <li>
            يتمّ ضمان معايير التشخيص والعلاج العالية: في Estethica ستحصل على
            شهادة ضمان لنتائج العلاج.
          </li>
        </ul>
        <h3>المبنى والغرف</h3>
        <p>
          تم تصميم المستشفيات لخلق جوّ مريح ، مع غرف خاصة مؤثثة بشكل عصري ومجهزة
          بخدمة الإنترنت ومصممة من طابقين لراحة المريض ومرافقيه.
        </p>
        <p>
          مثال: تقع عيادة Estethica Atashehir في مبنى مكون من 8 طوابق ، بمساحة
          داخلية تبلغ 6000 متر مربع ، وتضم 12 غرفة عمليات حديثة.
        </p>
        <h3>لراحتك</h3>
        <p>
          تضمّ Estethica في مبناها عيادة التعافي والمنتجع &ldquo;SPA and
          WELLNESS&rdquo; لاستخدام المرضى، حيث يمكنهم الاسترخاء والاهتمام بصحتهم
          العامّة (مساج، طبّ صيني، جاكوزي، الخ) تحت إشراف طبي.
        </p>
        <h2>العلاجات</h2>
        <h3>الأمراض الجلدية والتجميل الطبي والجراحة الترميمية</h3>
        <ul>
          <li>
            الأطباء ذوو خبرة عالية - طرق علاج حديثة - طرق جراحية طفيفة التوغّل -
            معدات من الجيل الجديد - بيئة آمنة - منتجات مستوردة معتمدة طبيًا.
          </li>
          <li>
            يستخدم العلاج بالخلايا الجذعية المبتكر في: مشاكل الجلد ، تساقط الشعر
            ، الحروق والجروح ، والتجاعيد.
          </li>
          <li>
            تشمل علاجات العناية بالبشرة: العناية الكلاسيكية بالبشرة ، وعلاجات
            مقاومة الشيخوخة ، والعناية بالكولاجين ، والعناية بالذهب (24 قيراطًا
            من الذهب الخالص) ، وعلاج العيوب ، وعلاج حب الشباب ، والتقشير
            الكيميائي ، والتقشير الكربوني .
          </li>
        </ul>
        <h3>توسع الأوردة (الدوالي)</h3>
        <p>
          اعتمادًا على حجم المشكلة والخصائص الفرديّة ، يقترح الأطباء علاجًا طفيف
          التوغل (الترددات الراديوية ، الاستئصال بالليزر ، العلاج بالتصليب) أو
          جراحة الدوالي.
        </p>
        <p>
          JetPeel : (ماء ، أكسجين ، معادن ، محاليل وفيتامينات) لعلاج: البقع ،
          البشرة الجافة ، المسام العميقة (التقشير) ، العيوب ، الخطوط، الشيخوخة.
        </p>
        <h3>زراعة الشعر</h3>
        <p>
          تمّ تصميم آلة الزراعة المتعددة INRUT للحصول على أقصى قدر من الكفاءة مع
          الحد الأدنى من الألم. تقلل العملية التي تستغرق ساعة واحدة من فقدان
          الشعر بنسبة 90٪ في المتوسط.
        </p>
        <p>
          بدون حلاقة - بدون ألم أو تعب &ndash; عودة فوريّة إلى الروتين الطبيعي.
        </p>
        <h3>زراعة الشعر بالاقتطاف FUE</h3>
        <p>تخدير موضعي - بدون ندوب - بدون بقع.</p>
        <h3>زراعة الشعر DHI&nbsp;</h3>
        <p>
          فترة إعادة زراعة بصيلات الشعر التي تمّ جمعها تكون أقصر بكثير باستخدام
          هذه التقنية. وبالتالي فإنّ احتمال نمو الشعر بمجرد الزراعة يزداد أكثر.
        </p>
        <p>بدون ندوب - بدون شقوق - بدون غرز.&nbsp;</p>
        <h3>DHI Plus Gold</h3>
        <p>
          تقنية أعيد تطويرها بواسطة Estethica باستخدام إبرة ذات رأس ذهبي مع
          إضافة النبضات لتعزيز الإجراء. تنتمي براءة الاختراع بالكامل إلى
          Estethica.
        </p>
        <ul>
          <li>
            منظر أكثر كثافة &ndash; شعر متين - عودة سريعة للحياة اليومية - بدون
            ندوب - بدون شقوق - بدون غرز.
          </li>
          <li>
            لتعزيز نمو الشعر يمكن للمرضى الخضوع للميزوثيرابي والعلاج الحراري.
          </li>
        </ul>
        <h3>طب الأسنان وصحة الفم وطب أسنان الأطفال</h3>
        <p>
          يتم تنفيذ نموذج الأسنان الاصطناعية باستخدام تقنية CAD-CAM ، حيث يتم
          إنشاء نموذج بدلات الأسنان في يوم واحد. تتوفر أطقم الأسنان الكاملة
          والجزئية.
        </p>
        <p>
          يتمّ إجراء معظم إجراءات طب الأسنان (تصفيح البورسلين ، تبييض الأسنان ،
          تسوس الأسنان وعلاج أمراض اللثة) باستخدام أحدث تقنيات الليزر.
        </p>
        <h3>Veneers (قشور تجميلية)</h3>
        <p>
          يتم استخدام أكثر من نوع لتناسب بنية الفم والأسنان للمرضى. القشرة
          المفضلة عمومًا هي الزركونيوم ، والإيماكس ، والقشرة اللامينيت.
        </p>
        <h3>الزراعة</h3>
        <p>
          يتم استخدام زرعات الأسنان عالية الموثوقية. يشمل العلاج زراعة الأسنان
          المصغرة ، وتقنية All On 4، وتقنية All On 6.&nbsp;
        </p>
        <p>
          يتمّ إجراء تدخلات جراحيّة معقدة من قبل جراحي الوجه والفكين لتصحيح
          التشوهات.
        </p>
        <h2>الطب البديل</h2>
        <h3>العلاج بالإبر الصينية</h3>
        <p>
          الوخز بالإبر له فوائد علاجية للجهاز العضلي الهيكلي ، علاوة على ذلك
          يمكن أن يساعد بشكل كبير في إنقاص الوزن من خلال تحفيز إفراز الإندورفين
          (تقليل الشهية) ، وتنظيم النشاط الأيضي ، ومنع الآثار الجانبية مثل حرقة
          المعدة وزيادة / انخفاض مستويات السكر في الدم ، وتنظيم الهرمونات
          والإنزيم النشاط (تقليل التوتر).
        </p>
        <p>
          في تركيا ، يجب أن يكون ممارسو الوخز بالإبر من خريجي كلية الطب أو كلية
          طب الأسنان ويحتاجون إلى الحصول على شهادة ممارس مختص مقدمة من وزارة
          الصحة.
        </p>
        <h3>مركز ابن سينا - الطب التقليدي لابن سينا</h3>
        <p>يحدد الطبيب نوع الخلل في الجسم ويعمل على إعادة التوازن.</p>
        <ol>
          <li>
            علاج غذائي (طعام ، رائحة ، صوت ، نظر ، فكر ، لمس ، جنس ، اجتماعي)،
          </li>
          <li>الحجامة،</li>
          <li>استخراج الدم،</li>
          <li>العلقة (علاج هيرودوثيرابي).</li>
        </ol>
        <h3>عيادة الحياة الصحية والغذاء</h3>
        <p>
          يتمّ توفير خطط الأكل والتوعية المصممة خصيصاً للعديد من الحالات مثل:
          مرض السكري من النوعين 1 و 2 ، وأمراض القلب ، وأمراض الكلى ، وأمراض
          الكبد ، وارتفاع ضغط الدم ، وتغذية الرياضيين ، والتغذية مع الشيخوخة.
        </p>
        <p>
          بوتوكس المعدة هي عملية تستغرق 15 دقيقة تحت التخدير، يسمح بفقدان الوزن
          ما بين 7 و 15 كجم في إجراء واحد ، ويضمن فقدان 40٪ من الوزن الزائد خلال
          فترة 6-8 أشهر.
        </p>
      </div>
    </div>
  );
};

export default EstethicaAr;
