import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import { StyledEngineProvider } from "@mui/material/styles";

import "react-datepicker/dist/react-datepicker.css";
import "react-image-lightbox/style.css";
import "../node_modules/font-awesome/css/font-awesome.min.css";

import { Loader } from "common/components";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "./i18n";

import { AppContextProvider } from "store/app-store";
import { TreatmentsContextProvider } from "store/treatments-store";

require("./client/components/coming-soon/css/main.css");
require("./client/assets/css/bootstrap.min.css");
require("./client/assets/css/all.css");
require("./client/assets/css/all.min.css");
require("./client/assets/css/fontawesome.min.css");
require("./client/assets/plugins/owlcarousel/owl.carousel.min.css");
require("./client/assets/css/style.css");

ReactDOM.render(
  <StyledEngineProvider injectFirst>
    <Suspense fallback={<Loader />}>
      <AppContextProvider>
        <TreatmentsContextProvider>
          <App />
        </TreatmentsContextProvider>
      </AppContextProvider>
    </Suspense>
  </StyledEngineProvider>,
  document.getElementById("root")
);

reportWebVitals();
