import { Link } from "react-router-dom";
import Logo from "client/assets/images/logo.webp";

const YmpLogo = (props) => {
  const linkClassName = props.linkClassName || "menu-logo";
  const imageClassName = props.imageLinkName || "img-fluid";

  return (
    <Link
      to="/"
      className={linkClassName}
      onClick={() => (props.onClick ? props.onClick() : {})}
    >
      <img src={Logo} className={imageClassName} alt="Logo" />
    </Link>
  );
};

export default YmpLogo;
