import Pagination from "@mui/material/Pagination";
import React from "react";
import { TableInputType } from "common";
import NoDataFound from "./NoDataFound";

const DataTable = (props) => {
  const {
    rows,
    columns,
    onPageChange,
    totalPages,
    page,
    showActions,
    onEdit,
    onUpdateStatus,
    onDelete,
  } = props;
  return (
    <>
      <table className="table table-hover table-center mb-0">
        <thead>
          <tr>
            <th key="#">#</th>
            {columns?.map((column) => (
              <th
                key={column.id}
                align={column.align}
                style={{ minWidth: column.minWidth }}
                className={column.className}
              >
                {column.label}
              </th>
            ))}
            {showActions && <th key="action">Action</th>}
          </tr>
        </thead>
        <tbody>
          {rows?.length > 0 ? (
            rows?.map((row, i) => {
              return (
                <tr tabIndex={-1} key={i}>
                  <td key="#">{i + 1}</td>
                  {columns.map((column) => {
                    const value = row[column.id];
                    return (
                      <td key={column.id} align={column.align}>
                        {column.type && column.type === TableInputType.Image ? (
                          <img src={value} alt={column.label} />
                        ) : column.type &&
                          column.type === TableInputType.Toggle ? (
                          <div className="status-toggle">
                            <input
                              id={"status_" + i}
                              type="checkbox"
                              className="check"
                              checked={!!row.active}
                              value={!!row.active}
                              onChange={(e) => {
                                onUpdateStatus(row);
                              }}
                            />
                            <label
                              htmlFor={"status_" + i}
                              className="checktoggle"
                            >
                              checkbox
                            </label>
                          </div>
                        ) : column.format && typeof value === "number" ? (
                          column.format(value)
                        ) : (
                          value
                        )}
                      </td>
                    );
                  })}
                  {showActions && (
                    <td key="action">
                      <div className="table-action">
                        <a
                          onClick={() => onEdit(row)}
                          className="btn btn-sm bg-info-light"
                          data-toggle="modal"
                          title="edit"
                        >
                          <i className="fas fa-edit" />
                        </a>
                        <a
                          onClick={() => {
                            onDelete(row);
                          }}
                          className="btn btn-sm bg-danger-light"
                          title="delete"
                        >
                          <i className="fas fa-trash-alt" />
                        </a>
                      </div>
                    </td>
                  )}
                </tr>
              );
            })
          ) : (
            <NoDataFound
              colSpan={showActions ? columns?.length + 1 : columns?.length}
            />
          )}
        </tbody>
      </table>
      {totalPages > 1 ? (
        <Pagination
          className="centered"
          count={totalPages}
          variant="outlined"
          shape="rounded"
          showFirstButton
          showLastButton
          page={page}
          onChange={onPageChange}
        />
      ) : (
        <></>
      )}
    </>
  );
};

export default DataTable;
